import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  api = `${environment.api.search.url}`;
  // catalog = 'ss_store_catalog/_search';
  catalog = `${environment.api.search.catalog}`;

  constructor(
    private http: HttpClient
  ) { }

  async findProducts(term: string): Promise<any> {
    const q = `q=name:${term}`;
    const _size = 100;

    return this.http.get(`${this.api}/${this.catalog}?${q}&size=${_size}`)
      .toPromise();
  }

  async findProductsX(term: string): Promise<any> {
    const header = this._getHeader();
    const _size = 100;
    const q = {
      match: {
        name: term
      }
    };

    return this.http.post(`${this.api}/${this.catalog}`, { query: q, size: _size })
      .toPromise();
  }

  private _getHeader(): any {
    const header = new HttpHeaders().set('Content-Type', 'application/json');

    return { headers: header };
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { AuthService } from "src/app/@core/auth/auth.service";
import { MESSAGES } from "src/app/@core/constants/validation";
import { NotifyService } from "src/app/@core/notify.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html'
})
export class SigninComponent implements OnInit {
    form: FormGroup;
    _type = new Subject<string>();
    type: string;
    validation_messages = MESSAGES;

    constructor(
        private _modalCtrl: ModalController,
        private _authService: AuthService,
        private _notifyService: NotifyService,
        private _storageService: StorageService,
        private _fb: FormBuilder
    ) { }

    ngOnInit() {
        this.form = this._fb.group({
            email: [
                (this._storageService.checkoutPersonalDetails) ?
                    this._storageService.checkoutPersonalDetails.email : null,
                [Validators.required, Validators.email]
            ]
        });

        this._type
            .subscribe(type => this.type = type);

        this._type.next('signin');
    }

    async signIn(data: any) {
        return this._authService.signIn(data)
            .then((customer: any) => {
                console.log('customer', customer);
                this._modalCtrl.dismiss({ continue: true });
            })
            .catch((error: any) => {
                console.log('error', error);
                this._notifyService.update(error.message, 'danger', 5000);
            });
    }

    dismiss(_continue?: any) {
        if (_continue === null) {
            this._modalCtrl.dismiss({ continue: _continue });
        } else {
            this._modalCtrl.dismiss();
        }
    }
}
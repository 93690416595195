import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { IonicModule } from '@ionic/angular';
import { CheckoutHeaderComponent } from './checkout-header/checkout-header.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputComponent } from './input/input.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HeaderNavComponent,
    CheckoutHeaderComponent,
    InputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [
    HeaderNavComponent,
    CheckoutHeaderComponent,
    InputComponent
  ]
})
export class ComponentsModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

const YEAR = 2019; // TODO move to config

export interface ISchoolService {
  getSchool(id: number): Promise<any>;
  getSupplyList(id: number): Promise<any>;
  getStarterKit(id: number): Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class SchoolService implements ISchoolService {

  api = `${environment.api.endpoint}/SchoolStore`;

  constructor(private http: HttpClient) { }

  async getSchool(id: number): Promise<any> {
    const filter = {
      include: [
        { relation: 'supplyLists' },
        {
          relation: 'schoolOptions', // TODO: restrict columns from this model
          scope: {
            where: { year: YEAR }
          }
        }
      ]
    };

    const f = JSON.stringify(filter);

    return this.http.get(`${this.api}/${id}?filter=${f}`)
      .toPromise();
  }

  async getSupplyList(id: number): Promise<any> {
    return this.http.get(`${this.api}/_supplyList?id=${id}`)
      .toPromise();
  }

  async getStarterKit(id: number): Promise<any> {
    return this.http.get(`${this.api}/_starterKit?id=${id}`)
      .toPromise();
  }

  async findSchools(term: string): Promise<any> {
    return this.http.post(`${this.api}/_search`, {
      name: term
    })
      .toPromise();
  }

  async findByCity(id: number) {
    const filter: any = {
      where: { cityId: id }
    };

    const f = JSON.stringify(filter);

    return this.http.get(`${this.api}?filter=${f}`)
      .toPromise();
  }

  async findByProvince(id: number, limit?: number, offset?: number): Promise<any> {
    const filter: any = {
      where: { provinceId: id },
      include: 'city'
    };

    if (limit) {
      filter.limit = limit;
    }

    if (offset) {
      filter.offset = offset;
    }

    const f = JSON.stringify(filter);

    return this.http.get(`${this.api}?filter=${f}`)
      .toPromise();
  }
}

@Inject({
  providedIn: 'root'
})
export class LegacySchoolService implements ISchoolService {
  api = `${environment.legacy.api.endpoint}`;
  schoolKit: any = {};

  constructor(private http: HttpClient) {}

  async getSchool(id: number): Promise<any> {
    const school = await this._school(id);

    if (school.current_options.delivery === null) {
      school.current_options.delivery = 'Home';
    }

    return {
      id: school.id,
      name: school.name,
      banner: school.banner,
      supplyLists: _.filter(school.kits, (k: any) => k.enabled),
      provinceId: school.address.province_id,
      schoolOptions: [school.current_options]
    };
  }

  async getSupplyList(id: number): Promise<any> {
    this.schoolKit = await this._schoolKit(id);

    const data = {
      supplylist: {
        id: this.schoolKit.id,
        starterKitId: this.schoolKit.id,
        name: this.schoolKit.name,
        discount: this.schoolKit.discount,
        fundraiser: this.schoolKit.fundraiser,
        notes: this.schoolKit.notes,
        shipping: this.schoolKit.shipping,
        products: [],
        schoolStore: {
          id: this.schoolKit.school.id,
          name: this.schoolKit.school.name,
          additional_requested: this.schoolKit.school.additional_requested
        }
      }
    };

    if (this.schoolKit.additional.length) {
      this.schoolKit.additional.forEach(product => {
        data.supplylist.products.push({
          quantity: product.quantity,
          unit: +(product.retail * product.quantity),
          pst_exempt: product.pst_exempt,
          product: {
            id: product.productId,
            name: product.name,
            retail: +product.retail
          }
        });
      });
    }

    return data;
  }

  async getStarterKit(id: number): Promise<any> {

    // Legacy call should get supply list for starter items
    if (!this.schoolKit.id) {
      await this.getSupplyList(id);
    }

    const data = {
      starterkit: {
        name: this.schoolKit.name,
        products: [],
        subtotal: this.schoolKit.products_subtotal,
        total: 0
      }
    };

    if (this.schoolKit.products.length > 0) {
      this.schoolKit.products.forEach((product: any) => {
        data.starterkit.products.push({
          quantity: product.quantity,
          unit: +(product.retail * product.quantity),
          pst_exempt: product.pst_exempt,
          product: {
            id: product.id,
            name: product.name
          }
        });

        data.starterkit.total += product.retail * product.quantity;
      });
    }

    return data;
  }

  async _school(id: number): Promise<any> {
    return this.http.get(`${this.api}/School/${id}`)
      .toPromise();
  }

  async _schoolKit(id: number): Promise<any> {
    return this.http.get(`${this.api}/SchoolKit/${id}?extended`)
      .toPromise();
  }

  async findSchools(term: string): Promise<any> {
    // Use existing API?
    return this.http.post(`${environment.api.endpoint}/SchoolStore/_search`, {
      name: term
    })
      .toPromise();
  }

  async findByCity(id: number) {
    const filter: any = {
      where: {
          cityId: id,
          disabled: 0
      }
    };

    const f = JSON.stringify(filter);

    return this.http.get(`${environment.api.endpoint}/SchoolStore?filter=${f}`)
      .toPromise();
  }

  async findByProvince(id: number, limit?: number, offset?: number): Promise<any> {
    const filter: any = {};

    if (limit) {
      filter.limit = limit;
    }

    if (offset) {
      filter.offset = offset;
    }

    const f = JSON.stringify(filter);

    return this.http.get(`${environment.api.endpoint}/SchoolStore/citiesByProvince?id=${id}`)
      .toPromise();
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomePageModule'
  },
  { path: 'checkout', loadChildren: './checkout/checkout.module#CheckoutPageModule' },
  { path: 'p', loadChildren: './product/product.module#ProductPageModule' },
  { path: 'confirmation', loadChildren: './checkout/confirmation/confirmation.module#ConfirmationPageModule' },
  { path: 'school', loadChildren: './school/school.module#SchoolPageModule' },
  { path: 'parents', loadChildren: './parents/parents.module#ParentsPageModule' },
  { path: 'account', loadChildren: './account/account.module#AccountPageModule' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
  { path: 'parents', loadChildren: './parents/parents.module#ParentsPageModule' },
  { path: 'invoice', loadChildren: './account/invoice/invoice.module#InvoicePageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

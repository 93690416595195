import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';


const helper = new JwtHelperService();

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  modal: ModalController;
  config: any;
  jwtExpiry: Date;
  jwtExpired: boolean;

  environment = `${environment.name}`;
  version = `v${environment.version}`;
  env = this.environment.substring(0, 1);

  stored: Array<any> = [];

  constructor(private _storageService: StorageService) {
    this.config = environment;
  }

  ngOnInit() {
    if (this.config.name === 'Development') {
      const token = this._storageService.customer.accessToken;
      this.jwtExpiry = helper.getTokenExpirationDate(token);
      this.jwtExpired = helper.isTokenExpired(token);
    }
  }

}

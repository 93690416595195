import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { LoginComponent } from '../account/login/login.component';
import { AboutComponent } from '../components/about/about.component';
import { CitiesComponent } from '../components/cities/cities.component';
import { SigninComponent } from '../account/signin/signin.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _component = new Subject<any>();
  component = this._component.asObservable();
  @Output() dismissed = new EventEmitter<boolean>();
  data: any;
  continue: string;

  constructor(
    private _modalCtrl: ModalController) {}

  set type(type: string) {
    switch (type) {
      case 'login':
        this._component.next(LoginComponent);
        break;
      
      case 'signin':
        this._component.next(SigninComponent);
        break;

      case 'about':
        this._component.next(AboutComponent);
        break;

      case 'cities':
        this._component.next(CitiesComponent);
        break;

      default:
        break;
    }
  }

  async show(_component: any) {
    const modal = await this._modalCtrl.create({
      component: _component,
      componentProps: {
        data: this.data
      }
    });

    modal.onDidDismiss()
      .then((response: any) => {
        this.data = response.data;
        if (this.data &&
            (this.data.continue || this.data.continue === null)) {
          this.continue = this.data.continue;
        }

        this.dismissed.emit(true);
      });

    return await modal.present();
  }

  dismiss() {
    this._modalCtrl.dismiss();
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastController } from '@ionic/angular';

export interface Msg {
  content: string;
  style: string;
  duration?: number;
  position?: 'top' | 'middle' | 'bottom';
}

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  private _msgSource = new Subject<Msg>();

  msg = this._msgSource.asObservable();

  constructor(private _toastCtrl: ToastController) { }

  update(content: string, style: string, duration?: number, position?) {
    const msg: Msg = { content, style, duration, position };
    this._msgSource.next(msg);
  }

  clear() {
    this._msgSource.next(null);
  }

  async toast(msg: Msg) {
    const _toast = await this._toastCtrl.create({
      message: msg.content,
      color: msg.style,
      duration: msg.duration,
      position: msg.position || 'top'
    });

    return await _toast.present();
  }
}

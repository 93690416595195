import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SchoolService, LegacySchoolService } from '../services/school.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: SchoolService,
          useClass: environment.legacy.enabled ? LegacySchoolService : SchoolService
        }
      ]
    };
  }
}

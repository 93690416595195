import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { PopoverService } from 'src/app/@core/popover.service';
import { ModalService } from 'src/app/@core/modal.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  loggedIn = false;
  customer: any;

  constructor(
    private _storageService: StorageService,
    private _authService: AuthService,
    private _modalService: ModalService,
    private _popoverService: PopoverService) { }

  ngOnInit() {
    this.customer = this._storageService.customer;
    this.loggedIn = (this.customer && this.customer.accessToken) ? true : false;

    this._authService.customer
      .subscribe(customer => {
        this.loggedIn = (customer && customer.accessToken) ? true : false;
      });
  }

  login() {
    this._modalService.type = 'login';
    this.dismiss();
  }

  signin() {
    this._modalService.type = 'signin';
    this.dismiss();
  }
  
  logout() {
    this.dismiss();
    this._authService.logout()
      .then(() => {
        this.loggedIn = false;
      });
  }

  dismiss() {
    this._popoverService.dismiss();
  }

}

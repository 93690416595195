import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import PROVINCES from '../@core/constants/provinces';
import * as _ from 'lodash';

export interface Province {
  id: number;
  name: string;
}

export interface City {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private _province = new Subject<Province>();
  private _city = new Subject<City>();

  province = this._province.asObservable();
  city = this._city.asObservable();

  constructor() {
    this._province.next(null);
    this._city.next(null);
  }

  provinces() {
    return _.sortBy(PROVINCES, ['name']);
  }

  getProvince(id: number) {
    // tslint:disable-next-line:object-literal-shorthand
    return _.find(PROVINCES, {id: id});
  }

  updateProvince(province: Province) {
    this._province.next(province);
  }

  updateCity(city: City) {
    this._city.next(city);
  }
}

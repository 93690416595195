import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './@core/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private _auth: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('store/access_token');
        const auth = request.headers.get('authorization');
        const isExpired = helper.isTokenExpired(token);

        // console.log(token, auth);
        // console.log('Expired...', isExpired);

        if (token && isExpired) {
            console.log('Signing out... token expired');
            this._auth.signOut();
        }

        return next.handle(request);
    }
}

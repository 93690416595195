import { Component, OnInit, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/@core/modal.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopoverService } from 'src/app/@core/popover.service';
import { MenuComponent } from 'src/app/account/components/menu/menu.component';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit {
  @Input() cartCount: number;
  customer: any = {};

  constructor(
    private _router: Router,
    private _menu: MenuController,
    private _authService: AuthService,
    private _storageService: StorageService,
    private _popoverService: PopoverService,
    private _modalService: ModalService) { }

  ngOnInit() {
    const helper = new JwtHelperService();
    this.customer = this._storageService.customer;

    this._authService.customer
      .subscribe(customer => {
        if (customer) {
          this.customer = customer;
          if (helper.isTokenExpired(this.customer.accessToken)) {
            this._authService.signOut();
          }
        }
      });
  }

  openMenu(name: string) {
    this._menu.enable(true, name);
    this._menu.open(name);
  }

  accountMenu(e: any) {
    this._popoverService.show(MenuComponent, e);
  }

  login() {
    if (this.customer && this.customer.accessToken) {
      this._router.navigate(['/account']);
    } else {
      this._modalService.type = 'login';
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input() validation_messages: any;
  @Input() disabled = false;
  @Input() readonly = false;
  @Output() edited = new EventEmitter<boolean>();
  @Output() focus = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onBlur(dirty: boolean) {
    this.edited.emit(dirty);
  }

  onFocus(name: any) {
    this.focus.emit(name);
  }

}

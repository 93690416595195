import { Injectable } from '@angular/core';
import * as _ from 'lodash';

declare var require: any;

const SecureStorage = require('secure-web-storage');
const Crypto = require('crypto-js');
const SECRET = 'Scho01st4rt';

const KEY = 'store';
const CHECKOUT_KEY = `${KEY}/checkout`;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  secureStorage = new SecureStorage(localStorage, {
    hash: (key: any) => {
      key = Crypto.SHA256(key, SECRET);

      return key.toString();
    },
    encrypt: (data: any) => {
      data = Crypto.AES.encrypt(data, SECRET);
      data = data.toString();

      return data;
    },
    decrypt: (data: any) => {
      data = Crypto.AES.decrypt(data, SECRET);
      data = data.toString(Crypto.enc.Utf8);

      return data;
    }
  });

  set cart(data: any) {
    this.secureStorage.setItem(`${KEY}/cart`, data);
  }

  get cart(): any {
    return this.secureStorage.getItem(`${KEY}/cart`);
  }

  removeCart() {
    this.secureStorage.removeItem(`${KEY}/cart`);
  }

  get checkoutPersonalDetails(): any {
    return this.secureStorage.getItem(`${CHECKOUT_KEY}/personal-details`);
  }

  set checkoutPersonalDetails(data: any) {
    this.secureStorage.setItem(`${CHECKOUT_KEY}/personal-details`, data);
  }

  get checkoutShipping(): any {
    return this.secureStorage.getItem(`${CHECKOUT_KEY}/shipping`);
  }

  set checkoutShipping(data: any) {
    this.secureStorage.setItem(`${CHECKOUT_KEY}/shipping`, data);
  }

  get checkoutPayment(): any {
    return this.secureStorage.getItem(`${CHECKOUT_KEY}/payment`);
  }

  set checkoutPayment(data: any) {
    this.secureStorage.setItem(`${CHECKOUT_KEY}/payment`, data);
  }

  get school(): any {
    return this.secureStorage.getItem(`${KEY}/school`);
  }

  set school(data: any) {
    this.secureStorage.setItem(`${KEY}/school`, data);
  }

  removeSchool() {
    this.secureStorage.removeItem(`${KEY}/school`);
  }

  getStoreFlag(name: string) {
    const flags = this.secureStorage.getItem(`${KEY}/flags`);
    return _.get(flags, name);
  }

  get storeFlags() {
    return this.secureStorage.getItem(`${KEY}/flags`);
  }

  set storeFlag(data: any) {
    const flags = this.storeFlags;

    this.secureStorage.setItem(`${KEY}/flags`, _.merge(data, flags));
  }

  removeStoreFlag(flag: string) {
    const flags = this.storeFlags;
    delete flags[flag];

    this.secureStorage.setItem(`${KEY}/flags`, flags);
  }

  get customer() {
    const data = this.secureStorage.getItem(`${KEY}/customer`);
    return data || {};
  }

  set customer(data: any) {
    this.secureStorage.setItem(`${KEY}/customer`, data);

    if (data.accessToken) {
      localStorage.setItem(`${KEY}/access_token`, data.accessToken);
    }
  }

  removeCustomer() {
    this.secureStorage.removeItem(`${CHECKOUT_KEY}/shipping`);
    this.secureStorage.removeItem(`${KEY}/customer`);
    localStorage.removeItem(`${KEY}/access_token`);
  }

  // Checkout cleanup
  cleanupCheckout() {
    this.removeStoreFlag('featuredSeen');
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FavouriteService } from '../services/favourite.service';
import { ToastController, MenuController } from '@ionic/angular';
import { NotifyService } from '../@core/notify.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss'],
})
export class FavouritesComponent implements OnInit {
  @Input() favourites: Array<any> = [];

  constructor(
    private _menu: MenuController,
    private favouriteService: FavouriteService,
    private _notifyService: NotifyService) { }

  ngOnInit() {}

  removeItem(id: number) {
    this.favouriteService.remove(id)
      .then(res => this._notifyService.update(res, 'success', 3000));
  }

  openMenu(name: string) {
    this._menu.enable(true, name);
    this._menu.open(name);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  results: Array<any> = [];
  productId: number;

  constructor(
    private _router: Router,
    private _menu: MenuController,
    private searchService: SearchService) { }

  ngOnInit() {
    this._menu.enable(true, 'search');
  }

  search(e: any) {
    const term = e.detail.value;
    this.searchService.findProducts(term)
      .then(results => this.results = results.hits.hits);
  }

  handleProductId() {
    this._router.navigate(['/p', this.productId]);
    this._menu.close();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { Subject } from 'rxjs';
import { ConfirmValidator } from '../components/confirm-validator';
import { NotifyService } from 'src/app/@core/notify.service';
import { StorageService } from 'src/app/services/storage.service';
import { MESSAGES, VALIDATORS } from '../../@core/constants/validation';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  error: string = null;
  _type = new Subject<string>();
  type: string;
  validation_messages = MESSAGES;

  constructor(
    private _authService: AuthService,
    private _modalCtrl: ModalController,
    private _notifyService: NotifyService,
    private _storageService: StorageService,
    private _customerService: CustomerService,
    private _fb: FormBuilder) { }

  ngOnInit() {

    this.form = this._fb.group({
      email: [
        (this._storageService.checkoutPersonalDetails) ?
        this._storageService.checkoutPersonalDetails.email : null,
        [Validators.required, Validators.email]
      ],
      firstname: [
        (this._storageService.checkoutPersonalDetails) ?
        this._storageService.checkoutPersonalDetails.firstname : null,
        [Validators.required, Validators.minLength(VALIDATORS.firstname.length)]
      ],
      lastname: [
        (this._storageService.checkoutPersonalDetails) ?
        this._storageService.checkoutPersonalDetails.lastname : null,
        [Validators.required, Validators.minLength(VALIDATORS.lastname.length)]
      ],
      password: [null, [Validators.required, Validators.minLength(VALIDATORS.password.length)]],
      repeatPassword: [null, [Validators.required, Validators.minLength(VALIDATORS.password.length)]]
    }, {
      validator: ConfirmValidator.MatchPassword
    });

    this._type
      .subscribe(type => this.type = type);

    this._type.next('login');
  }

  login(data: any): Promise<any> {
    return this._authService.login(data)
      .then(res => {
        this._customerService.customer
          .then((customer: any) => {

            if (!customer.address_id) {
              this._customerService.createAddress()
                .then(address => {
                  this._customerService.updateCustomerAddress({
                    id: customer.id,
                    address_id: address.id
                  })
                    .catch(err => {
                      console.error(err);
                      const error = err.error.error.message;
                      this._notifyService.update(error, 'Error updating address');
                    });
                });
            }

            data = {
              phone: customer.phone
            };

            if (customer.address) {
              data.address = customer.address.line1;
              data.city = customer.address.city;
              data.province = {id: customer.address.province_id};
              data.postcode = customer.address.postcode;
              data.pobox = customer.address.pobox;

            }

            this._storageService.checkoutShipping = data;
          });

        this.dismiss();
      })
      .catch(err => {
        console.error(err);
        // this.error = err.error.error.message;
        this._notifyService.update(err.error.error.message, 'danger', 5000);
    });
  }

  reset(data: any) {
    this._authService.requestReset(data)
    .then(res => {
      // this.dismiss();
      this._type.next('login');
      this._notifyService.update('Please check your email for reset instructions.', 'success', 5000);
    })
    .catch(err => {
      console.log(err);
      // this.error = err.error.error.message;
      this._notifyService.update(err.error.error.message, 'danger', 5000);
    });
  }

  register(data: any) {
    this._authService.register(data)
    .then(res => {
      this.dismiss();
    })
    .catch(err => {
      console.error(err);
      // this.error = err.error.error.message;
      this._notifyService.update(err.error.error.message, 'danger', 5000);
    });
  }

  dismiss(_continue?: any) {
    if (_continue === null) {
      this._modalCtrl.dismiss({ continue: _continue });
    } else {
      this._modalCtrl.dismiss();
    }
  }

}

const PROVINCES: any[] = [
    { id: 1, name: 'British Columbia', code: 'BC' },
    { id: 2, name: 'Alberta', code: 'AB' },
    { id: 3, name: 'Saskatchewan', code: 'SK' },
    { id: 4, name: 'Manitoba', code: 'MB' },
    { id: 5, name: 'Ontario', code: 'ON' },
    { id: 6, name: 'Nova Scotia', code: 'NS' },
    { id: 7, name: 'Newfoundland & Labrador', code: 'NL' },
    { id: 9, name: 'New Brunswick', code: 'NB' },
    { id: 10, name: 'Northwest Territories', code: 'NT' },
    { id: 11, name: 'Nunavut', code: 'NU' },
    { id: 12, name: 'Prince Edward Island', code: 'PE' },
    { id: 13, name: 'Quebec', code: 'QC' },
    { id: 14, name: 'Yukon Territory', code: 'YT' }
];

export default PROVINCES;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavouritesComponent } from './favourites.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [FavouritesComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FontAwesomeModule
  ],
  exports: [FavouritesComponent]
})
export class FavouritesModule { }

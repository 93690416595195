import { Component, OnInit } from '@angular/core';
import { Province } from 'src/app/services/address.service';
import { AddressService } from 'src/app/services/address.service';

@Component({
  selector: 'app-provinces',
  templateUrl: './provinces.component.html',
  styleUrls: ['./provinces.component.scss'],
})
export class ProvincesComponent implements OnInit {
  provinces: Array<any>;

  constructor(private _addressService: AddressService) { }

  ngOnInit() {
    this.provinces = this._addressService.provinces();
  }

  selectProvince(province: Province) {
    this._addressService.updateProvince(province);
  }

}

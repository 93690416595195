import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {

  constructor(private _popoverCtrl: PopoverController) { }

  async show(_component: any, _event: any) {
    const _popover = await this._popoverCtrl.create({
      component: _component,
      event: _event
    });

    return await _popover.present();
  }

  dismiss() {
    this._popoverCtrl.dismiss();
  }
}

export const environment = {
    name: 'staging',
    production: false,
    version: require('../../package.json').version,
    client: 'https://staging.store.schoolstart.ca',
    api: {
        endpoint: 'https://api2.schoolstart.ca:3000/api',
        search: {
            url: 'https://api2.schoolstart.ca',
            catalog: 'ss_store_catalog/_search'
        }
    },
    legacy: {
        enabled: true,
        api: {
            endpoint: 'https://api2.schoolstart.ca'
        }
    },
    stripe: {
        key: 'pk_test_NN8impEwZ76Hqu89xYIlSntY'
    }
};

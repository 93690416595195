import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SearchComponent } from './search/search.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CartModule } from './cart/cart.module';
import { FavouritesModule } from './favourites/favourites.module';
import { CoreModule } from './@core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { AboutComponent } from './components/about/about.component';
import { LoginComponent } from './account/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CitiesComponent } from './components/cities/cities.component';
import { ComponentsModule } from './components/components.module';
import { MenuComponent } from './account/components/menu/menu.component';
import { TokenInterceptor } from './token.interceptor';
import { ProvincesComponent } from './components/provinces/provinces.component';
import { CitylistComponent } from './components/citylist/citylist.component';
import { SigninComponent } from './account/signin/signin.component';

library.add( fas, far );

const APP_COMPONENTS = [
  AppComponent,
  SearchComponent,
  AboutComponent,
  LoginComponent,
  SigninComponent,
  CitiesComponent,
  CitylistComponent,
  ProvincesComponent,
  MenuComponent
];

const helper = new JwtHelperService();

@NgModule({
  declarations: [APP_COMPONENTS],
  entryComponents: [AboutComponent, LoginComponent, SigninComponent, CitiesComponent, MenuComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CoreModule.forRoot(),
    CartModule,
    FavouritesModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        skipWhenExpired: true,
        whitelistedDomains: [
          'localhost:3000',
          '192.168.1.68:3000',
          '192.168.1.68:8000',
          'api2.schoolstart.ca',
          'api2.schoolstart.ca:3000',
          'apistaging.schoolstart.ca',
          'apistaging.schoolstart.ca:3001'
        ]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function getToken() {
      const token = localStorage.getItem('store/access_token');
      // const isExpired = helper.isTokenExpired(token);

      // console.log('Expired...', isExpired);

      // if (token && isExpired) {
      //   token = null;
      //   localStorage.removeItem('store/access_token');
      //   console.log('Token removed... expired');
      // }
      return token;
    }

export const MESSAGES = {
    firstname: [
        { type: 'required', message: 'A First Name is required.' },
        { type: 'minlength', message: 'Must be at least 2 characters.' }
    ],
    lastname: [
        { type: 'required', message: 'A Last Name is required.' },
        { type: 'minlength', message: 'Must be at least 2 characters.' }
    ],
    address: [
        { type: 'required', message: 'An Address is required.' },
        { type: 'minlength', message: 'Must be at least 4 characters.' }
    ],
    city: [
        { type: 'required', message: 'A City is required.' }
    ],
    postcode: [
        { type: 'required', message: 'A Postcode is required.' }
    ],
    phone: [
        { type: 'required', message: 'A Telephone # is required.' }
    ],
    student: [
        { type: 'required', message: 'A Student Name is required.' },
        { type: 'minlength', message: 'Must be a least 2 characters.' }
    ],
    email: [
        { type: 'required', message: 'An Email Address is required.' },
        { type: 'email', message: 'A Valid Email is required.' }
    ],
    password: [
        { type: 'required', message: 'A Password is required.' },
        { type: 'minlength', message: 'Password must at least 6 characters.' }
    ]
};

export const VALIDATORS = {
    firstname: { length: 2 },
    lastname: { length: 2 },
    password: { length: 6 },
    address: { length: 4 },
    student: { length: 2 }
};

import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/@core/modal.service';
import { AuthService } from 'src/app/@core/auth/auth.service';

@Component({
  selector: 'app-checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.scss'],
})
export class CheckoutHeaderComponent implements OnInit {
  customer: any = {};
  featuredSeen = false;

  constructor(
    private _authService: AuthService,
    private _storageService: StorageService,
    private _modalService: ModalService,
    private _router: Router,
    private _location: Location) { }

  ngOnInit() {
    this.customer = this._storageService.customer;
    this._authService.customer
      .subscribe(customer => this.customer = customer);

    this.featuredSeen = this._storageService.getStoreFlag('featuredSeen');
  }

  login() {
    this._modalService.type = 'login';
  }

  goback() {
    if (this.featuredSeen) {
      this._router.navigate(['/']);
    } else {
      this._location.back();
    }
  }
}

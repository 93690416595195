import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import * as _ from 'lodash';
import { ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

const PAGE_SIZE = 20;

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements OnInit {
  data: any;
  cities: any[] = [];
  pages: any[] = [];
  items: any[] = [];
  filteredItems: any;
  filterControl: FormControl;

  page = 0;

  constructor(
    private _modalCtrl: ModalController,
    private _schoolService: SchoolService) {
      this.filterControl = new FormControl();
    }

  ngOnInit() {
    this.filterItems('');

    this.filterControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe(term => {
        this.filterItems(term)
          .then(items => {
            this.filteredItems = items;
          });
      });

    this._schoolService.findByProvince(this.data)
      .then(response => {
        this.cities = response.cities;
        this.pages = _.chunk(this.cities, PAGE_SIZE);
        this.loadItems();
      });
  }

  loadItems() {
    return new Promise((resolve) => {
      this.pages[this.page].forEach(item => {
        this.items.push(item);
      });
      this.filteredItems = this.items;

      resolve();
    });
  }

  loadMore(event: any) {
    this.page++;
    this.loadItems()
      .then(() => event.target.complete());
  }

  filterItems(term: string) {
    return new Promise((resolve) => {
      resolve(this.cities.filter(item => {
        return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
      }));
    });
  }

  selectItem(item: any) {
    this._modalCtrl.dismiss(item);
  }

  dismiss() {
    this._modalCtrl.dismiss();
  }
}

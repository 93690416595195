import { Component } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CartService } from './services/cart.service';
import { FavouriteService } from './services/favourite.service';
import { NotifyService, Msg } from './@core/notify.service';
import { ModalService } from './@core/modal.service';
import { Router } from '@angular/router';
import { StorageService } from './services/storage.service';
import { AuthService } from './@core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Search',
      click: 'search',
      icon: 'search'
    },
    {
      title: 'My Orders',
      url: '/account/orders',
      icon: 'list-ul'
    },
    {
      title: 'About',
      click: 'about',
      icon: 'info-circle'
    }
  ];

  favourites: Array<any> = [];
  cart: any = {};
  summary: any = {
    subtotal: 0,
    total: 0
  };

  customer: any;
  loggedIn = false;

  constructor(
    private _router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private cartService: CartService,
    private favouriteService: FavouriteService,
    private _notifyService: NotifyService,
    private _modalService: ModalService,
    private _storageService: StorageService,
    private _authService: AuthService,
    private _menu: MenuController
  ) {
    this.initializeApp();

    this.customer = this._storageService.customer;
    this._authService.customer
      .subscribe(customer => {
        this.customer = (customer) ? customer : null;
        this.loggedIn = (this.customer && this.customer.accessToken) ? true : false;
      });

    this.loggedIn = (this.customer && this.customer.accessToken) ? true : false;

    this.cart = this.cartService.getCart() || [];
    this._shoppingSummary();

    this.cartService.cart
      .subscribe((cart: any) => {
        this.cart = cart;
        if (cart) {
          this._shoppingSummary();
        }
      });

    this.favourites = this.favouriteService.getFavourites() || [];
    this.favouriteService.favourites
      .subscribe(favourites => this.favourites = favourites);

    this._notifyService.msg
      .subscribe((msg: Msg) => this._notifyService.toast(msg));

    this._modalService.component
      .subscribe(component => this._modalService.show(component));

    this._modalService.dismissed
      .subscribe(() => {
        if (this._modalService.continue) {
          this._router.navigate([this._modalService.continue]);
        }
      });
  }

  private _shoppingSummary() {
    this.summary.subtotal = 0;

    if (this.cart.lists) {
      this.cart.lists.forEach((item: any) => {
        this.summary.subtotal += item.list.total;
      });
    }

    if (this.cart.products) {
      this.cart.products.forEach((product: any) => {
        this.summary.subtotal += product.unit;
      });
    }

    this.summary.total = this.summary.subtotal;
  }

  click(item: string) {
    switch (item) {
      case 'about':
        this._modalService.type = item;
        break;

      case 'search':
        this._menu.enable(true, item);
        this._menu.open(item);
    }
    // this[item]();
  }

  async showModal(type: string) {
    console.log(type);
    this._modalService.type = type;
  }

  emptyCart() {
    this.cartService.emptyCart()
      .then(res => this._notifyService.update(res, 'success', 3000))
      .catch(err => this._notifyService.update(err.statusText, 'danger', 5000));
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}

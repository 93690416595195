import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../services/cart.service';
import { NotifyService } from '../@core/notify.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Input() cart: any = {};
  @Input() summary: any = {};
  @Input() checkout = false;
  count: number;

  constructor(
    private _router: Router,
    private _menu: MenuController,
    private cartService: CartService,
    private _notifyService: NotifyService) { }

  ngOnInit() {
    this.count = this.cartService.getCount();
    this.cartService.count
      .subscribe((count: number) => this.count = count);
  }

  updateItem(item: any) {
    if (!item.quantity) {
      return;
    }

    item.unit = item.retail * item.quantity;

    this.cartService.updateCartItem(item)
      .then(res => this._notifyService.update(res, 'success', 3000))
      .catch(err => this._notifyService.update(err.statusText, 'danger', 5000));
  }

  removeItem(id: number) {
    this.cartService.removeFromCart(id)
      .then(res => this._notifyService.update(res, 'success', 3000))
      .catch(err => this._notifyService.update(err.statusText, 'danger', 5000));
  }

  removeList(item: any) {
    this.cartService.removeListFromCart(item)
      .then(res => this._notifyService.update(res, 'success', 3000))
      .catch(err => this._notifyService.update(err.statusText, 'danger', 5000));
  }

  findLists() {
    this._router.navigate(['/school']);
  }

  openMenu(name: string) {
    this._menu.enable(true, name);
    this._menu.open(name);
  }

  emptyCart() {
    this.cartService.emptyCart();
  }

  goToCheckout() {
    this._router.navigate(['/checkout'], { replaceUrl: true } );
  }
}

import { AbstractControl } from '@angular/forms';

export class ConfirmValidator {
    static MatchPassword(control: AbstractControl) {
        const password = control.get('password');
        const repeatPassword = control.get('repeatPassword');

        if (password.value !== repeatPassword.value) {
            repeatPassword.setErrors({ RepeatPassword: true });
        } else {
            return null;
        }
    }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  api = `${environment.api.endpoint}`;

  constructor(private _http: HttpClient) { }

  get customer() {
    return this._http.post(`${this.api}/Customers/byCustomer`, {})
      .toPromise();
  }

  createAddress(): Promise<any> {
    return this._http.post(`${this.api}/CustomerAddresses`, {})
      .toPromise();
  }

  updateCustomer(data: any): Promise<any> {
    return this._http.post(`${this.api}/Customers/updateInfo`, {info: data})
      .toPromise();
  }

  updateCustomerAddress(data: any): Promise<any> {
    return this._http.patch(`${this.api}/Customers/${data.id}`, data)
      .toPromise();
  }
}

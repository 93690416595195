import { Injectable, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { StorageService } from './storage.service';

const KEY = 'store/cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  @Output() cart = new EventEmitter<Array<any>>();
  @Output() count = new EventEmitter<number>();
  homedelivery = false;

  constructor(private _storeService: StorageService) {}

  getCount(): number {
    const cart = this.getCart();
    const lists = (cart && cart.lists) ? cart.lists.length : 0;
    const products = (cart && cart.products) ? cart.products.length : 0;

    return lists + products;
  }

  getCart(): any {
    return this._storeService.cart || {};
  }

  isStoreOnly(): boolean {
    const cart = this.getCart();
    return (cart.lists) ? false : true;
  }

  isHomeDelivery() {
    const cart = this.getCart();
    if (cart.products) {
      _.forEach(cart.products, (product: any) => {
        if (!product.featured) {
          this.homedelivery = true;
        }
      });
    }

    return this.homedelivery;
  }

  addListToCart(list: any): Promise<string> {
    let message: string;
    const cart = this.getCart();

    const differentSchool = _.find(cart.lists, (l: any) => {
      return l.list.schoolStore.id !== list.list.schoolStore.id;
    });

    if (!cart.lists) {
      cart.lists = [];
    }

    return new Promise((resolve, reject) => {

      if (differentSchool) {
        reject('You cannot add a kit from a different school.  Please choose the same school.');
        return;
      }

      const idx = _.findIndex(cart.lists, { id: list.id, student: list.student });

      if (idx === -1) {
        list.quantity = 1;
        cart.lists.push(list);
        message = 'Added To Cart';
      } else {
        list.quantity++;
        message = 'Cart Updated';
      }

      this._storeService.cart = cart;

      this.cart.emit(cart);
      this.count.emit(this.getCount());
      resolve(message);
    });
  }

  addtoCart(item: any): Promise<string> {
    let message: string;
    const cart = this.getCart();

    if (!cart.products) {
      cart.products = [];
    }

    return new Promise(resolve => {
      const idx = _.findIndex(cart.products, { id: item.id });

      if (idx === -1) {
        item.unit = item.retail * item.quantity;
        cart.products.push(item);
        message = 'Added To Cart Products';
      } else {
        cart.products[idx].quantity += item.quantity;
        cart.products[idx].unit = cart.products[idx].retail * cart.products[idx].quantity;
        message = 'Cart Product Updated';
      }

      this._storeService.cart = cart;

      this.cart.emit(cart);
      this.count.emit(this.getCount());
      resolve(message);
    });
  }

  updateCartItem(item: any): Promise<string> {
    const cart = this.getCart();
    const idx = _.findIndex(cart.products, { id: item.id });
    let error: string;

    if (idx === -1) {
      error = 'Item not in cart';
    } else {
      cart.products[idx] = item;
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        this._storeService.cart = cart;

        this.cart.emit(cart);

        resolve('Cart Updated');
      }
    });
  }

  removeListFromCart(item: any): Promise<string> {
    const cart = this.getCart();
    const idx = _.findIndex(cart.lists, { id: item.id, student: item.student });
    let error: string;

    if (idx === -1) {
      error = 'Item not found in cart';
    } else {
      cart.lists.splice(idx, 1);
      if (cart.lists.length === 0) {
        delete cart.lists;
      }
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        if (cart.lists || cart.products) {
          this._storeService.cart = cart;
        }

        // cleanup...
        if (!cart.products && !cart.lists) {
          this._storeService.removeCart();
        }

        this.cart.emit(cart);
        this.count.emit(this.getCount());
        resolve('Item removed from cart!');
      }
    });
  }

  removeFromCart(itemId: number): Promise<string> {
    const cart = this.getCart();
    const idx = _.findIndex(cart.products, { id: itemId });
    let error: string;

    if (idx === -1) {
      error = 'Product not in cart';
    } else {
      cart.products.splice(idx, 1);
      if (cart.products.length === 0) {
        delete cart.products;
      }
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {

        if (cart.products || cart.lists) {
          this._storeService.cart = cart;
        }

        // cleanup...
        if (!cart.products && !cart.lists) {
          this._storeService.removeCart();
        }

        this.cart.emit(cart);
        this.count.emit(this.getCount());

        resolve('Product Removed from cart!');
      }

    });
  }

  emptyCart(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._storeService.removeCart();
      this.cart.emit(null);
      this.count.emit(0);

      this._storeService.removeSchool();
      this._storeService.removeStoreFlag('featuredSeen');

      resolve('Cart Emptied');
    });
  }
}

import { Injectable, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

const KEY = 'store/favourites';

@Injectable({
  providedIn: 'root'
})
export class FavouriteService {
  @Output() favourites = new EventEmitter<Array<any>>();
  @Output() last = new EventEmitter<any>();

  constructor() { }

  getFavourites(): Array<any> {
    const favourites = localStorage.getItem(KEY);
    return JSON.parse(favourites);
  }

  isFavourite(itemId: number): boolean {
    const favourites = this.getFavourites() || [];
    const idx = _.findIndex(favourites, { id: itemId });

    return (idx === -1) ? false : true;
  }

  add(item: any): Promise<string> {
    const favourites = this.getFavourites() || [];
    const idx = _.findIndex(favourites, { id: item.id });
    let message: string;

    if (idx === -1) {
      favourites.push(item);
      message = 'Added To Your Favourites';
    }

    return new Promise((resolve, reject) => {
      if (idx !== -1) {
        reject('This item is already in your Wishlist');
      }

      localStorage.setItem(KEY, JSON.stringify(favourites));

      this.favourites.emit(favourites);
      this.last.emit({id: item.id, action: 'added'});

      resolve(message);
    });
  }

  remove(itemId: any): Promise<string> {
    const favourites = this.getFavourites() || [];
    const idx = _.findIndex(favourites, { id: itemId });
    let error: string;

    if (idx === -1) {
      error = 'Favourite item not found';
    } else {
      favourites.splice(idx, 1);
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {

        if (favourites.length) {
          localStorage.setItem(KEY, JSON.stringify(favourites));
        } else {
          localStorage.removeItem(KEY);
        }

        this.favourites.emit(favourites);
        this.last.emit({id: itemId, action: 'removed'});

        resolve('Favourite Item Removed');
      }
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SchoolService } from 'src/app/services/school.service';
import { AddressService, City } from 'src/app/services/address.service';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

const PAGE_SIZE = 20;

@Component({
  selector: 'app-citylist',
  templateUrl: './citylist.component.html',
  styleUrls: ['./citylist.component.scss'],
})
export class CitylistComponent implements OnInit {
  cities: any[] = [];
  pages: any[] = [];
  items: any[] = [];
  filteredItems: any;
  filterControl: FormControl;

  page = 0;

  constructor(
    private _addressService: AddressService,
    private _schoolService: SchoolService) {
    this.filterControl = new FormControl();
  }

  ngOnInit() {
    this.filterItems('');

    this.filterControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe(term => {
        this.filterItems(term)
          .then(items => {
            this.filteredItems = items;
          });
      });

    this._addressService.province
      .subscribe(p => {
        if (p) {
          this.findCities(p.id);
        }
      });
  }

  findCities(province: any) {
    if (province !== undefined) {
      this._schoolService.findByProvince(province)
      .then(response => {
          this.cities = response.cities;
          this.pages = _.chunk(this.cities, PAGE_SIZE);
          this.loadItems();
        });
    }
  }

  loadItems() {
    return new Promise((resolve) => {
      this.filteredItems = null;
      this.items = [];

      this.pages[this.page].forEach(item => {
        this.items.push(item);
      });
      this.filteredItems = this.items;

      resolve();
    });
  }

  loadMore(event: any) {
    this.page++;
    this.loadItems()
      .then(() => event.target.complete());
  }

  filterItems(term: string) {
    return new Promise((resolve) => {
      resolve(this.cities.filter(item => {
        return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
      }));
    });
  }

  selectItem(city: City) {
    this._addressService.updateCity(city);
  }

}
